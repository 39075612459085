import { IExceptionTelemetry } from '@microsoft/applicationinsights-web'

import { appInsightsIsEnabled, wasInitializedOrThrow } from '../helpers'
import { useAppInsights } from '../use-application-insights'

export function trackAppInsightsError(err: Error): void {
  if (!appInsightsIsEnabled()) return
  try {
    const appInsights = useAppInsights()
    if (wasInitializedOrThrow(appInsights)) {
      const exception: IExceptionTelemetry = { exception: err }
      appInsights.trackException(exception)
    }
  } catch (err) {
    console.error(err)
  }
}
