<template>
  <div class="text-red-500">
    <svg
      aria-hidden="true"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="m12,3l-8,8l8,8l8,-8l-8,-8zm-9.9,8l9.9,9.9l9.9,-9.9l-9.9,-9.9l-9.9,9.9z"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </svg>
  </div>
</template>
