import { watch } from 'vue'

import * as Sentry from '@sentry/vue'

import router from '@/router/index.js'

import { useAuth } from '../composables/auth'

const sentry = {
  install: app => {
    Sentry.init({
      app,
      environment: import.meta.env.VITE_ENVIRONMENT_NAME,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
      ],
      attachProps: true,
      trackComponents: true,
      tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
      tracePropagationTargets: ['facility.sortera.org'],
      replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSIONS_SAMPLE_RATE),
      replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),

      ignoreErrors: [
        'Non-Error exception captured',
        'Failed to fetch dynamically',
        'timeout of',
        'redirect_in_iframe',
        'interaction_in_progress',
        'ResizeObserver loop limit',
        'FirebaseError',
        '<unknown>',
      ],
    })

    watch(
      useAuth().account,
      (currentAccount, prevAccount) => {
        const currentMail = currentAccount?.email ?? null
        const prevMail = prevAccount?.email ?? null
        if (currentMail !== prevMail) {
          Sentry.setUser({
            email: currentMail ?? undefined,
            username: currentAccount?.name,
            id: currentAccount?.accountId,
          })
        }
      },
      { immediate: true, deep: true }
    )
  },
}

export default sentry
