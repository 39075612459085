import {
  ApplicationInsights,
  IConfig,
  IConfiguration,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web'

import { useAuth } from '@/composables/auth'
import router from '@/router/index.js'
import store from '@/state/store.js'

import {
  GLOBAL_PROP_PAGE_NAME,
  GLOBAL_PROP_ROLE_NAME,
  GLOBAL_PROP_STATION,
  GLOBAL_PROP_USER_EMAIL,
} from './constants'
import { appInsightsIsEnabled, wasInitializedOrThrow } from './helpers'
import { setAppInsights, useAppInsights } from './use-application-insights'

const GOOGLE_BASE_DOMAIN = 'googleapis.com'

export function initAppInsights(): void {
  let appInsights = useAppInsights()
  if (appInsights !== undefined || !appInsightsIsEnabled()) return
  const config: IConfig & IConfiguration = {
    connectionString: import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
  }
  try {
    appInsights = new ApplicationInsights({ config })
    appInsights.addTelemetryInitializer(addRoleNameToTelemetryItem)
    appInsights.addTelemetryInitializer(addUserToTelemetryItem)
    appInsights.addTelemetryInitializer(addStationToTelemetryItem)
    appInsights.addTelemetryInitializer(addPageViewToTelemetryItem)
    appInsights.addTelemetryInitializer(googleDependencyTelemetryFilter)
    appInsights.loadAppInsights()
    setAppInsights(appInsights)
  } catch (err) {
    console.error(err)
  }
}

export function destroyAppInsights(): void {
  const appInsights = useAppInsights()
  if (appInsights !== undefined) {
    appInsights.flush()
    setAppInsights(undefined)
  }
}

export function addRoleNameToTelemetryItem(item: ITelemetryItem): boolean {
  if (!Array.isArray(item.tags)) {
    item.tags = []
  }
  item.tags = [{ 'ai.cloud.role': GLOBAL_PROP_ROLE_NAME }]
  return true
}

export function addUserToTelemetryItem(item: ITelemetryItem): boolean {
  try {
    const { account } = useAuth()

    const appInsights = useAppInsights()
    if (account.value && wasInitializedOrThrow(appInsights)) {
      if (account.value?.accountId) {
        appInsights.setAuthenticatedUserContext(account.value.accountId)
      }

      item.data = {
        ...(item.data ?? {}),
        [GLOBAL_PROP_USER_EMAIL]: account.value.email,
      }
    }
  } catch (err) {
    console.error(err)
  }
  return true
}

export function addStationToTelemetryItem(item: ITelemetryItem): boolean {
  if (store.getters.currentStationName) {
    item.data = {
      ...(item.data ?? {}),
      [GLOBAL_PROP_STATION]: store.getters.currentStationName,
    }
  }

  return true
}

export function addPageViewToTelemetryItem(item: ITelemetryItem): boolean {
  const pageName = router.currentRoute.value.name || router.currentRoute.value.fullPath
  item.data = {
    ...(item.data ?? {}),
    [GLOBAL_PROP_PAGE_NAME]: pageName,
  }
  return true
}

export function googleDependencyTelemetryFilter(item: ITelemetryItem): boolean {
  function isDependency(item: ITelemetryItem) {
    return item.baseType === 'RemoteDependencyData'
  }

  function isCallingGoogle(item: ITelemetryItem) {
    return (
      item.baseData?.name?.includes(GOOGLE_BASE_DOMAIN) ||
      item.baseData?.target?.includes(GOOGLE_BASE_DOMAIN)
    )
  }

  function wasSuccessful(item: ITelemetryItem) {
    return item.baseData?.responseCode >= 200 && item.baseData?.responseCode < 400
  }

  if (!isDependency(item) || !item.baseData) {
    return true
  }

  if (!isCallingGoogle(item)) {
    return true
  }

  if (!wasSuccessful(item)) {
    return true
  }

  return false
}
