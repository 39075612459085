<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'

import { getFailedRequestsCount, useAuthState } from '@/utils/auth-state'

const { t } = useI18n()
const failedRequestCountTopic = useAuthState()

const failureCount = ref<number>(0)
const hasNotifications = computed(() => failureCount.value > 0)

function showAlert() {
  alert(t('auth.failed-multiple-times'))
}

function updateFailureCount() {
  failureCount.value = getFailedRequestsCount()
}

function onFailedRequestCountUpdated(count: number) {
  failureCount.value = count
}

onMounted(() => {
  updateFailureCount()
  failedRequestCountTopic.subscribe(onFailedRequestCountUpdated)
})

onUnmounted(() => {
  failedRequestCountTopic.unsubscribe(onFailedRequestCountUpdated)
})
</script>
<template>
  <button v-if="hasNotifications" class="btn btn-circle text-red-600" @click="showAlert">
    <ExclamationCircleIcon class="h-10" />
  </button>
</template>
