import '@/plugins/luxon.js'

import { createApp } from 'vue'
import flatPickr from 'vue-flatpickr-component'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { firestorePlugin } from 'vuefire'

import { VueQueryPlugin } from '@tanstack/vue-query'

import loadComponents from '@/components/_register-base-components'
import i18n from '@/i18n'
import { usePluginApplicationInsights } from '@/plugins/application-insights'
import sentry from '@/plugins/sentry.js'
import { appInsightsIsEnabled } from '@/utils/application-insights/index'

import store from './state/store'
import App from './App.vue'
import router from './router'

import '@/assets/global.scss'

const app = createApp(App)

if (import.meta.env.VITE_SENTRY_DISABLED !== 'true') {
  app.use(sentry)
}

app.use(i18n) // since we use translations in router, we need to init i18n before router
loadComponents(app)

app.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 60 * 24,
        // staleTime: 1000 * 10,
      },
    },
  },
})

app.component('FlatPickr', flatPickr)
app.component('VueMultiselect', Multiselect)
app.component('VSelect', vSelect)
app.component('DynamicScroller', DynamicScroller)
app.component('DynamicScrollerItem', DynamicScrollerItem)
app.use(firestorePlugin, { wait: true })
app.use(store)
app.use(router)

if (appInsightsIsEnabled()) {
  usePluginApplicationInsights(app)
}

app.mount('#app')
