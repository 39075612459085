/**
 * CAREFUL WHEN CHANGING THESE CONSTANTS - Our analytics are based on these values.
 *
 * NAMING CONVENTIONS
 *
 * --- EVENTS ---
 * - OBJECT ACTION
 * - Title Case with spaces
 * - Action in past tense
 *
 * --- PROPERTIES ---
 * - PascalCase
 */

// GLOBAL PROPERTIES
export const GLOBAL_PROP_ROLE_NAME = 'FacilityView' as const
export const GLOBAL_PROP_USER_EMAIL = 'UserEmail' as const
export const GLOBAL_PROP_STATION = 'Station' as const
export const GLOBAL_PROP_PAGE_NAME = 'PageName' as const

export const EVENT_AUTH_LOGGED_IN = 'Logged In' as const

export const EVENT_AUTH_LOGGED_OUT = 'Logged Out' as const

export const EVENT_AUTH_REQUESTED_TOKEN = 'Requested Access Token' as const

export const EVENT_AUTH_INITIALIZED = 'Initialized Auth' as const
export const PROP_AUTH_INITIALIZED_AUTH_REQUIRED = 'AuthRequired' as const

export const EVENT_AUTH_FAILED_GATEWAY_AUTHORIZATION = 'Failed Gateway Authorization' as const
export const PROP_AUTH_CONTEXT = 'FailureContext' as const // 'tokenRetrieval' | 'responseStatus'
export const PROP_AUTH_FAILURE_COUNT = 'FailureCount' as const
export const PROP_AUTH_FAILURE_SHOULD_RETRY = 'ShouldRetry' as const
