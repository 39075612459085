import get from 'lodash/get'
import set from 'lodash/set'

import i18n from '@/i18n'
import MainLayout from '@/layouts/MainLayout.vue'
import { db } from '@/plugins/db'
import store from '@/state/store'

const { t } = i18n.global

async function getReference(documentReference) {
  const res = await documentReference.get()
  const data = res.data()

  if (data && documentReference.id) {
    data.uid = documentReference.id
  }

  return data
}
// https://stackoverflow.com/a/60822132/4299925
function hydrate(document, paths = []) {
  return Promise.all(
    paths.map(async path => {
      const documentField = get(document, path)
      if (documentField.constructor.name === 'Array') {
        for (let i = 0; i < documentField.length; i++) {
          const documentReference = documentField[i]
          if (!documentReference || !documentReference.path) {
            return console.warn(
              `Error hydrating documentReference for path "${path}": Not found or invalid reference`
            )
          }
          const result = await getReference(documentReference)
          documentField[i] = result
        }
      } else {
        const documentReference = documentField
        if (!documentReference || !documentReference.path) {
          return console.warn(
            `Error hydrating documentReference for path "${path}": Not found or invalid reference`
          )
        }

        const result = await getReference(documentReference)
        set(document, path, result)
      }
    })
  )
}

export const ROUTES = {
  HOME: 'home',
  OVERVIEW: 'overview',
  PLOT_DUMPED: 'dumped',
  PLOT: 'plot',
  SUMMARIES: 'summaries',
  SUMMARY: 'summary',
  LASTMILE: 'lm',
  TL_OVERVIEW: 'avvikelse',
  KPI: 'kpi',
  PULSE: 'pulse',
  CONVERT: 'convertToParked',
  SETTINGS: 'settings',
  SIGN_IN: 'sign-in',
  _404: '404',
  AUTH_HANDLER: 'authHandler',
  AUTH_IFRAME: 'authIframe',
}

/** @type {import('vue-router').RouterOptions['routes']} */
export default [
  // NO LAYOUT

  {
    // Prevent cross-domain storage issues by hosting auth helpers ourselves:
    // https://firebase.google.com/docs/auth/web/redirect-best-practices#self-host-helper-code
    path: '/__/auth/handler',
    name: ROUTES.AUTH_HANDLER,
    component: () => import('@/views/AuthHelperRedirect.vue'),
  },
  {
    path: '/__/auth/iframe',
    name: ROUTES.AUTH_IFRAME,
    component: () => import('@/views/AuthHelperRedirect.vue'),
  },

  // WRAPPED IN MAIN LAYOUT
  {
    path: '/',
    component: MainLayout,
    // all children wrapped in layout
    children: [
      {
        path: '/',
        name: ROUTES.HOME,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Facilities.vue'),
      },
      {
        path: '/overview',
        redirect: '/',
        replace: true,
      },
      {
        path: '/settings',
        name: ROUTES.SETTINGS,
        meta: { authRequired: true, title: t('settings.title') },
        component: () => import('@/views/settings-view.vue'),
      },
      {
        path: '/facility/:facilityId',
        meta: {
          authRequired: true,
          beforeResolve(to, _, next) {
            if (store.currentStation && store.currentStation.id === to.params.facilityId) next()
            else
              db.collection('vehicleStations')
                .doc(to.params.facilityId)
                .get()
                .then(snapshot => {
                  if (!snapshot.exists) {
                    next({ path: '/', replace: true })
                  } else {
                    const doc = snapshot.data()
                    store.commit('SET_STATE', { currentStation: doc })

                    next()
                  }
                })
          },
        },
        redirect: to => ({ name: ROUTES.OVERVIEW, params: to.params }),
        children: [
          {
            path: 'overview',
            name: ROUTES.OVERVIEW,
            meta: { authRequired: true },
            component: () => import('@/views/Overview.vue'),
          },
          {
            path: 'dumped/:plotId',
            name: ROUTES.PLOT_DUMPED,
            meta: {
              authRequired: true,
              beforeResolve(to, _, next) {
                db.collection('plots')
                  .doc(to.params.plotId)
                  .get()
                  .then(async snapshot => {
                    if (!snapshot.exists) {
                      next({ path: 'overview', params: to.params, replace: true })
                    } else {
                      const doc = snapshot.data()
                      await hydrate(doc, ['entries'])
                      store.commit('SET_STATE', { currentPlot: doc })

                      next()
                    }
                  })
              },
            },
            component: () => import('@/views/Dumped.vue'),
          },
          {
            path: 'plot/:plotName',
            name: ROUTES.PLOT,
            meta: { authRequired: true },
            component: () => import('@/views/Plot.vue'),
          },
        ],
      },
      {
        path: '/finance',
        name: ROUTES.SUMMARIES,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Summaries.vue'),
      },
      {
        path: '/finance/order/:orderId',
        name: ROUTES.SUMMARY,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Summary.vue'),
      },
      {
        path: '/lm',
        name: 'lastmile',
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Lastmile.vue'),
      },
      {
        path: '/avvikelse',
        name: 'avvikelse',
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Avvikelse.vue'),
      },
      {
        path: '/kpi',
        name: 'kpi',
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Kpi.vue'),
      },
      {
        path: '/kalender',
        name: 'pulse',
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/PulseCalendar.vue'),
      },
      {
        path: '/move',
        name: 'moveToPlot',
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/MoveToPlot.vue'),
      },
      {
        path: '/convert',
        name: 'convertToParked',
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/ConvertToParked.vue'),
      },
      { path: '/:path(.*)', component: () => import('@/views/NotFound.vue') },
    ],
  },
]
