import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights: ApplicationInsights | undefined

export function useAppInsights(): ApplicationInsights | undefined {
  return appInsights
}

export function setAppInsights(val: ApplicationInsights | undefined): void {
  appInsights = val
}
