<script lang="ts" setup>
import { computed } from 'vue'

import Tr from '@/i18n/translation'
import { UiEnvBanner } from '@/ui/ui-env-banner'
import { getBackendEnvName, getFirebaseEnvName } from '@/utils/env'

const { VITE_ENVIRONMENT_NAME, VITE_FAVICON_URL } = import.meta.env

const env = computed(
  () =>
    ({
      frontendEnv: VITE_ENVIRONMENT_NAME,
      backendEnv: getBackendEnvName(),
      firebaseEnv: getFirebaseEnvName(),
    }) as const
)

function addEnvFavicon() {
  if (!VITE_FAVICON_URL) {
    console.error('VITE_FAVICON_URL is not set in .env.local file')
    return
  }
  const linkEl = document.createElement('link')
  linkEl.setAttribute('rel', 'icon')
  linkEl.setAttribute('href', VITE_FAVICON_URL)
  document.head.appendChild(linkEl)
}

Tr.switchLanguage(Tr.guessDefaultLocale())

addEnvFavicon()
</script>
<template>
  <UiEnvBanner v-bind="env" />
  <RouterView />
</template>
