type Callback<TArgType> = (data: TArgType) => void

export class PubSub<TArgType> {
  private callbacks: Callback<TArgType>[] = []

  public subscribe(callback: Callback<TArgType>): void {
    this.callbacks.push(callback)
  }

  public unsubscribe(callback: Callback<TArgType>): void {
    this.callbacks = this.callbacks.filter(fn => fn !== callback)
  }

  public publish(data: TArgType): void {
    this.callbacks.forEach(callback => callback(data))
  }
}
