import 'firebase/compat/auth'
import 'firebase/compat/performance'
import 'firebase/compat/firestore'

import firebase from 'firebase/compat/app'

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  // Prevent cross-domain storage issues by hosting auth helpers ourselves:
  // https://firebase.google.com/docs/auth/web/redirect-best-practices#self-host-helper-code
  authDomain: window.location.host,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
}

export const firebaseApp = firebase.initializeApp(config)

export const db = firebaseApp.firestore()

export const perf = firebase.performance()
