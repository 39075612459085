<script>
import NavBar from '@/components/navbar.vue'

export default {
  components: { NavBar },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.$store.commit('SET_TITLE', to.meta.title)
      },
    },
  },
}
</script>

<template>
  <div class="artboard mx-auto flex min-h-screen max-w-screen-lg flex-col">
    <NavBar />
    <div class="flex flex-1 flex-col bg-light-back dark:bg-dark-back">
      <slot />
      <RouterView :key="$route.fullPath" />
    </div>
  </div>
</template>
