<script>
export default {
  inheritAttrs: false,
  props: {
    user: {
      default: null,
      type: Object,
    },
    name: {
      default: null,
      type: String,
    },
    data: {
      default: null,
      type: String,
    },
    hideTooltip: {
      default: false,
      type: Boolean,
    },
    round: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    cleanName() {
      if (this.name && this.name.includes('@')) return this.name.split('@')[0].replaceAll('.', ' ')
      return this.name
    },
    imageData() {
      if (this.user && this.user.imageData) return this.user.imageData
      if (this.data && this.data.length > 0) return this.data
      if (this.name && this.name.length > 0)
        return `https://icotar.com/initials/${encodeURIComponent(this.cleanName)}.png`
      return 'https://i.imgur.com/L2Py2dG.png'
    },
  },
}
</script>

<template>
  <div class="avatar">
    <div v-bind="$attrs" :class="{ 'rounded-full': round, 'mask mask-squircle': !round }">
      <img
        class="object-cover"
        :src="imageData"
        :title="cleanName && !hideTooltip ? cleanName : null"
      />
    </div>
  </div>
</template>
