import { RouteLocation } from 'vue-router'

import { IPageViewTelemetry } from '@microsoft/applicationinsights-web'

import { appInsightsIsEnabled, wasInitializedOrThrow } from '../helpers'
import { useAppInsights } from '../use-application-insights'

export function trackAppInsightsPageView(route: RouteLocation): void {
  const appInsights = useAppInsights()
  if (!appInsightsIsEnabled()) return
  try {
    if (wasInitializedOrThrow(appInsights)) {
      const { name, fullPath } = route
      const pageView: IPageViewTelemetry = {
        name: (name as string) ?? fullPath,
        uri: fullPath,
      }
      appInsights.trackPageView(pageView)
    }
  } catch (err) {
    console.error(err)
  }
}
