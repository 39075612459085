export function getLocalStorageItem<T>(key: string): T | null {
  const item = localStorage.getItem(key)
  return isNotEmpty(item) ? JSON.parse(item) : null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any --- JSON.stringify accepts any, therefore should we
export function setLocalStorageItem(key: string, item: any): void {
  localStorage.setItem(key, JSON.stringify(item))
}

export function removeLocalStorageItem(key: string): void {
  localStorage.removeItem(key)
}

function isNotEmpty(value?: string | null): value is string {
  return typeof value === 'string' && value !== '' && value !== 'null'
}
