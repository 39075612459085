import { useAppInsights } from '@/utils/application-insights'
import {
  EVENT_AUTH_FAILED_GATEWAY_AUTHORIZATION,
  EVENT_AUTH_INITIALIZED,
  EVENT_AUTH_LOGGED_IN,
  EVENT_AUTH_LOGGED_OUT,
  EVENT_AUTH_REQUESTED_TOKEN,
  PROP_AUTH_CONTEXT,
  PROP_AUTH_FAILURE_COUNT,
  PROP_AUTH_FAILURE_SHOULD_RETRY,
  PROP_AUTH_INITIALIZED_AUTH_REQUIRED,
} from '@/utils/application-insights/constants'
import { trackCustomEvent } from '@/utils/application-insights/helpers'

import {
  EventAuthFailedGatewayAuthorization,
  EventAuthInitialized,
  EventAuthLoggedIn,
  EventAuthLoggedOut,
  EventAuthRequestedToken,
} from '../types'

export function trackAppInsightsLoggedIn(): void {
  const appInsights = useAppInsights()
  const event: EventAuthLoggedIn = {
    name: EVENT_AUTH_LOGGED_IN,
  }
  trackCustomEvent(appInsights, event)
}

export function trackAppInsightsLoggedOut(): void {
  const appInsights = useAppInsights()
  const event: EventAuthLoggedOut = {
    name: EVENT_AUTH_LOGGED_OUT,
  }
  trackCustomEvent(appInsights, event)
}

export function trackAppInsightsRequestedToken(): void {
  const appInsights = useAppInsights()
  const event: EventAuthRequestedToken = {
    name: EVENT_AUTH_REQUESTED_TOKEN,
  }
  trackCustomEvent(appInsights, event)
}

export function trackAppInsightsInitializedAuth(authRequired: boolean): void {
  const appInsights = useAppInsights()
  const event: EventAuthInitialized = {
    name: EVENT_AUTH_INITIALIZED,
    properties: {
      [PROP_AUTH_INITIALIZED_AUTH_REQUIRED]: authRequired,
    },
  }
  trackCustomEvent(appInsights, event)
}

export function trackAppInsightsFailedGatewayAuthorization(
  context: 'tokenRetrieval' | 'responseStatus',
  failureCount: number,
  shouldRetry: boolean
): void {
  const appInsights = useAppInsights()
  const event: EventAuthFailedGatewayAuthorization = {
    name: EVENT_AUTH_FAILED_GATEWAY_AUTHORIZATION,
    properties: {
      [PROP_AUTH_CONTEXT]: context,
      [PROP_AUTH_FAILURE_COUNT]: failureCount,
      [PROP_AUTH_FAILURE_SHOULD_RETRY]: shouldRetry,
    },
  }
  trackCustomEvent(appInsights, event)
}
