import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import _pickBy from 'lodash/pickBy'

import { TrackEvent } from './types'

export function appInsightsIsEnabled(): boolean {
  // eslint-disable-next-line eqeqeq
  return import.meta.env.VITE_APPLICATION_INSIGHTS_DISABLED != 'true'
}

export function trackCustomEvent(
  appInsights: ApplicationInsights | undefined,
  event: TrackEvent
): void {
  if (!appInsightsIsEnabled()) return
  try {
    if (wasInitializedOrThrow(appInsights)) {
      const eventWithoutUndefined =
        event.properties == null
          ? event
          : {
              ...event,
              properties: _pickBy(event.properties, value => value !== undefined),
            }
      appInsights.trackEvent(eventWithoutUndefined)
    }
  } catch (err) {
    console.error(err)
  }
}

export function wasInitializedOrThrow(
  appInsights: ApplicationInsights | undefined
): appInsights is ApplicationInsights {
  if (appInsights === undefined) {
    throw new Error('Application Insights must be initialized before use')
  }
  return true
}
