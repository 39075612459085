import { ref } from 'vue'

import { Authentication, type User } from '@sortera/firebase-auth'

import { firebaseApp } from '@/plugins/db.js'
import {
  trackAppInsightsInitializedAuth,
  trackAppInsightsLoggedIn,
  trackAppInsightsLoggedOut,
  trackAppInsightsRequestedToken,
} from '@/utils/application-insights'
import { resetFailedRequestsCount } from '@/utils/auth-state'

const auth = new Authentication(
  {
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    redirectUri: window.location.origin + '/autentisering/logga-in',
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AUTH_TENANT_ID}`,
    scopes: [import.meta.env.VITE_AUTH_GATEWAY_SCOPE],
    tenantId: import.meta.env.VITE_AUTH_TENANT_ID,
  },
  firebaseApp
)

const account = ref<User | null>(null)

async function initializeAuth(options?: Parameters<typeof auth.initializeAuth>[0]) {
  if (account.value != null) {
    // eslint-disable-next-line no-console
    console.log('Auth already initialized for', options?.caller, account.value.email)
    return
  }
  trackAppInsightsInitializedAuth(options?.authRequired ?? false)
  // eslint-disable-next-line no-console
  console.log('Initializing auth for', options?.caller)

  account.value = await auth.initializeAuth(options)
  return account.value
}

function login(): Promise<void> {
  trackAppInsightsLoggedIn()
  // eslint-disable-next-line no-console
  console.log('Logging in')

  return auth.login()
}

async function logout(): Promise<void> {
  trackAppInsightsLoggedOut()
  // eslint-disable-next-line no-console
  console.log('Logging out')

  account.value = null
  // failed request count is reset upon successful axios request, but not all pages make requests to the gateway
  resetFailedRequestsCount()
  await auth.logout()
}

async function getAccessToken(): Promise<string> {
  trackAppInsightsRequestedToken()
  // eslint-disable-next-line no-console
  console.log('Getting access token')
  return await auth.getAccessToken()
}

export function useAuth() {
  return {
    account,
    initializeAuth,
    login,
    logout,
    getAccessToken,
  }
}
