import i18n from '@/i18n'

/**
 * Object containing methods for handling translations and locales.
 * @typedef {Object} Trans
 * @property {string} defaultLocale - The default locale.
 * @property {string} currentLocale - The current locale.
 * @property {function} switchLanguage - Function to switch the current locale to a new one.
 * @property {function} isLocaleSupported - Function to check if a locale is supported.
 * @property {function} getUserLocale - Function to get the user's locale.
 * @property {function} getPersistedLocale - Function to get the user's persisted locale.
 * @property {function} guessDefaultLocale - Function to guess the default locale based on user preferences and fallbacks.
 */
const Trans = {
  get defaultLocale() {
    return import.meta.env.VITE_DEFAULT_LOCALE
  },

  get currentLocale() {
    return i18n.global.locale.value
  },

  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale
  },

  switchLanguage(newLocale) {
    Trans.currentLocale = newLocale
    document.querySelector('html').setAttribute('lang', newLocale)
    localStorage.setItem('user-locale', newLocale)
  },

  isLocaleSupported(locale) {
    return i18n.global.availableLocales.includes(locale)
  },

  getUserLocale() {
    const locale = window.navigator.language || window.navigator.userLanguage || Trans.defaultLocale

    return {
      locale: locale,
      localeNoRegion: locale.split('-')[0],
    }
  },

  getPersistedLocale() {
    const persistedLocale = localStorage.getItem('user-locale')

    if (Trans.isLocaleSupported(persistedLocale)) return persistedLocale

    return null
  },

  /**
   * Guesses the default locale based on the user's persisted locale, user's preferred locale, and fallback locale.
   * @returns {string} The default locale.
   */
  guessDefaultLocale() {
    const userPersistedLocale = Trans.getPersistedLocale()

    if (userPersistedLocale) return userPersistedLocale

    const userPreferredLocale = Trans.getUserLocale()

    if (Trans.isLocaleSupported(userPreferredLocale.locale)) return userPreferredLocale.locale

    if (Trans.isLocaleSupported(userPreferredLocale.localeNoRegion))
      return userPreferredLocale.localeNoRegion

    return i18n.global.fallbackLocale
  },
}

export default Trans
