import { App } from 'vue'

import router from '@/router/index.js'
import {
  initAppInsights,
  trackAppInsightsError,
  trackAppInsightsPageView,
} from '@/utils/application-insights'

export function usePluginApplicationInsights(app: App) {
  initAppInsights()

  const prevErrorHandler = app.config.errorHandler
  app.config.errorHandler = (err, instance, info) => {
    if (err instanceof Error) {
      trackAppInsightsError(err)
    }
    // ensure to not override any other error handler:
    prevErrorHandler?.(err, instance, info)
    throw err
  }

  router.beforeEach((to, from, next) => {
    trackAppInsightsPageView(to)
    next()
  })

  router.onError(err => {
    trackAppInsightsError(err)
  })
}
